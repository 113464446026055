import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import { slotsData } from "../../../../lib/data/slots.data";

import PlayBtn from "../../../../assets/images/play-btn.png";

import { useSelector } from "react-redux";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";

const Slots = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <>
      <HeaderAfterLogin />

      <main className="main mt-4">
        <div className="slots-section">
          <div className="games-section">
            <div className="d-flex justify-content-between align-items-center PageHeading">
              <h2 className="">Slots</h2>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </div>
            </div>

            <ul className="mt-4">
              {Object.values(slotsData.slots).map((item) => {
                return (
                  <li className="game-section">
                    <a href={isAuth ? item.redirectUrl : "/login"}>
                      <img src={item.imgUrl} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                      <p>{item.name}</p>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Slots;
